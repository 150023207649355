<template>
	<div>
		<bonus-list-filters
			:code-filter.sync="codeFilter"
			:group-id-filter.sync="groupIdFilter"
			:group-name-options="groupNameOptions"
			:currency-filter.sync="currencyFilter"
			:currency-options="currencyOptions"
			:product-id-filter.sync="productIdFilter"
			:product-options="productOptions"
			:discount-type-filter.sync="discountTypeFilter"
			:type-options="typeOptions"
			:status-filter.sync="statusFilter"
			:status-options="statusOptions"
			:fromdate-filter.sync="fromdateFilter"
			:todate-filter.sync="todateFilter"
		/>
		<b-card
			no-body
		>
			<div class="m-2">
				<b-row>
					<b-col
						cols="12"
						v-if="$can('create', 'bonuscode')"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddBonusModal"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> New
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<b-table
				ref="refBonusListTable"
				class="position-relative table-white-space mb-0 max-height-table"
				sticky-header
				hover
				head-variant="light"
				:no-border-collapse="true"
				:items="bonusCodeLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>
				<template #cell(name)="data">
				<b-link
					class="cursor-pointer"
					@click="showEditBonusModal(data.item)"
				>
					{{data.item.name}}
				</b-link>
				</template>
				<template #cell(group)="data">
					{{ data.item.bonus_group ? data.item.bonus_group.name : ''}}
				</template>

				<template #cell(value)="data">
					{{ numberFormat(data.item.value / 1000) }}
				</template>

				<template #cell(product)="data">
					{{ data.item.product ? data.item.product.name : ''}}
				</template>

				<template #cell(type)="data">
					{{ resolveType(data.item.discount_type) }}
				</template>

				<template #cell(bonusStatus)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveBonusStatus(data.item.start_effective, data.item.end_effective).variant}`" />{{ resolveBonusStatus(data.item.start_effective, data.item.end_effective).label }}
					</div>
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.created_at ? data.item.created_at : '') }}
				</template>

				<template #cell(action)="data">
					<b-link
						v-b-tooltip.hover.v-info
						:title="$t('Edit')"
						class="font-weight-bold text-nowrap mr-1"
						style="cursor: pointer;"
						@click="showEditBonusModal(data.item)"
						v-if="$can('edit', 'bonuscode')"
					>
						Edit
					</b-link>
					<!-- <b-link
						v-b-tooltip.hover.v-info
						:title="$t('View')"
						class="font-weight-bold text-nowrap mr-1"
						style="cursor: pointer;"
						@click="viewBonusModal(data.item)"
						v-if="$can('view', 'bonuscode')"
					>
						View
					</b-link> -->
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalBonus"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<add-bonus-modal
			:bonus-code-options="bonusCodeOptions"
			:group-name-options="groupNameOptions"
			:currency-options="currencyOptions"
			:product-options="productOptions"
			:type-options="typeOptions"
			:bonus-type-options="bonusTypeOptions"
			@refetch-data="refetchData"
		/>
		<!-- Edit Bonus Modal -->
		<edit-bonus-modal
			:bonus-detail.sync="bonusDetail"
			:bonus-code-options="bonusCodeOptions"
			:group-name-options="groupNameOptions"
			:currency-options="currencyOptions"
			:product-options="productOptions"
			:type-options="typeOptions"
			:bonus-type-options="bonusTypeOptions"
			@refetch-data="refetchData"
		/>
	
	</div>
</template>

<script>
import {
  BButton, BModal, VBModal,
  BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { formatDateTime, numberFormat } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import bonusStoreModule from '@/views/bonus/bonusStoreModule'
import currencyStoreModule from '@/views/local-setting/local/currencyStoreModule'
import BonusListFilters from './BonusListFilters.vue'

export default {
	components: {
		BonusListFilters,
		AddBonusModal: () => import('./AddBonusModal.vue'),
		EditBonusModal: () => import('./EditBonusModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
	},
	directives: {
    	'b-tooltip':VBTooltip,
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
			bonusDetail: null,
		}
	},
	mounted() {
		this.bonusGroupList()
		this.getCurrency()
		this.getBonusCodeProduct()
		this.bonusTypeList()
	},
	methods: {
		showAddBonusModal() {
			this.$bvModal.show('modal-add-bonus')
		},
		showEditBonusModal(datas) {
			this.bonusDetail = datas
			this.$bvModal.show('modal-edit-bonus')
		},
		
		bonusGroupList() {
			store.dispatch('bonus/fetchBonusGroups', {
				page: 1,
			})
				.then(response => {
					const { bonusGroups } = response
					this.groupNameOptions = bonusGroups
				})
		},
		bonusTypeList() {
			store.dispatch('bonus/fetchBonusTypes')
				.then(response => {
					this.bonusTypeOptions = response.data.data
				})
		},
		getCurrency() {
			store.dispatch('local-setting-currency/fetchCurrencies')
				.then(response => {
					if (response.data.code == '00') {
						this.currencyOptions = response.data.data
					}
				})
		},
		getBonusCodeProduct() {
			store.dispatch('bonus/fetchBonusCodeProduct')
				.then(response => {
					if (response.data.code == '00') {
						this.productOptions = response.data.data
					}
				})
		}
	},
	setup() {
		if (!store.hasModule('local-setting-currency')) store.registerModule('local-setting-currency', currencyStoreModule)
		if (!store.hasModule('bonus')) store.registerModule('bonus', bonusStoreModule)
		const toast = useToast()
		const bonusLists = ref([])
		const perPage = ref(25)
		const totalBonus = ref(0)
		const currentPage = ref(1)
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const codeFilter = ref(null)
		const groupIdFilter = ref(null)
		const currencyFilter = ref(null)
		const productIdFilter = ref(null)
		const discountTypeFilter = ref(null)
		const statusFilter = ref(null)
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)
		const refBonusListTable = ref(null)
		const groupNameOptions = ref([])
		const currencyOptions = ref([])
		const productOptions = ref([])
		const bonusCodeOptions = ref(null)
		const bonusTypeOptions = ref([])
		const tableColumns = [
			{ key:'index', label: '#' },
			'code',
			{ key: 'name', stickyColumn: true },
			'group',
			'value',
			'status',
			'product',
			'type',
			// 'currency',
			'created_by',
			// 'bonusStatus',
			// 'memberApply',
			// { key: 'start_effective', label: 'Start Effective Date' },
			// { key: 'end_effective', label: 'End Effective Date' },
			{ key: 'action' }, // , stickyColumn: true, label: 'Action', variant: 'danger' },
		]

		const refetchData = () => {
			refBonusListTable.value.refresh()
		}

		watch([currentPage], () => {
			refetchData()
		})

		const bonusCodeLists = (ctx, callback) => {
			store.dispatch('bonus/fetchBonusCodes', {
				code: codeFilter.value,
				group_id: groupIdFilter.value,
				currency: currencyFilter.value,
				product_id: productIdFilter.value,
				discount_type: discountTypeFilter.value,
				status: statusFilter.value,
				start_effective: fromdateFilter.value,
				end_effective: todateFilter.value,
				page: currentPage.value,
			})
				.then(response => {
					if (response.data.code == '00') {
						bonusCodeOptions.value = response.data.data
						callback(response.data.data)
						totalBonus.value = response.data.count
						perPage.value = currentPage.value == 1 ? response.data.data.length : 25
					} else {
						toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})
					}
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refBonusListTable.value ? refBonusListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalBonus.value,
			}
		})

		const statusOptions = [
			{ label: 'Active', value: 1 },
			{ label: 'inActive', value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'inActive', variant: 'danger' }
			return { label: 'Pending', variant: 'secondary' }
		}

		const resolveType = type => {
			if (type == 1) return 'Cash'
			if (type == 2) return 'Percent'
			return '-'
		}

		const resolveBonusStatus = (start, end) => {
			const startDate = new Date(start)
			const endDate = new Date(end)
			const now = new Date()
			if (startDate <= now && now <= endDate) return { label: 'Live', variant: 'primary' }
			if (now > endDate) return { label: 'Expired', variant: 'danger' }
			if (now < startDate) return { label: 'Coming', variant: 'secondary' }
			return { label: '-', variant: 'secondary' }
		}

		const typeOptions = [
			{ label: 'Cash', value: 1 },
			{ label: 'Percent', value: 2 },
		]
		return {
			tableColumns,
			perPage,
			currentPage,
			totalBonus,
			dataMeta,
			sortBy,
			isSortDirDesc,
			refBonusListTable,

			bonusLists,
			bonusCodeLists,
			refetchData,

			codeFilter,
			groupIdFilter,
			groupNameOptions,
			currencyFilter,
			currencyOptions,
			productIdFilter,
			productOptions,
			discountTypeFilter,
			fromdateFilter,
			todateFilter,
			statusFilter,

			resolveStatus,
			resolveBonusStatus,
			statusOptions,
			resolveType,
			typeOptions,
			bonusCodeOptions,
			bonusTypeOptions,

			formatDateTime,
			numberFormat,
		}
	},
}
</script>
<style>
	.b-table-sticky-column {
		right: 0!important;
	}
	td.b-table-sticky-column {
		background-color: inherit;
	}
</style>